import { Camera, CylinderGeometry, Euler, Group, Matrix4, Plane, Quaternion, Raycaster, Vector3, Vector4 } from "three";
import * as BufferGeometryUtils from "three/examples/jsm/utils/BufferGeometryUtils.js";
import { memberWithPrivateData } from "../../Utils";
import { TransformSpace } from "./BoxControls";
import { Gizmo } from "./Gizmo";
import { DEFAULT_X_COLORS, DEFAULT_Y_COLORS, DEFAULT_Z_COLORS, GizmoHandle, GizmoHandleColors } from "./GizmoHandle";

/** The desired length in pixels of the axis */
const AXIS_LENGTH_IN_PIXELS = 50;

/** The radius of the cylinder. It will be scaled by the world matrix of the box */
const CYLINDER_RADIUS = 0.5;

/** Given the maximum of the three sizes of the box, the percentage of it used to determine the width of the axis */
const WIDTH_PERCENTAGE = 0.2;

/** Bottom radius of the arrow shaft */
const CONE_TOP_RADIUS = 0.75;

/** The geometry of the head of the arrow  */
const CONE_GEOMETRY = new CylinderGeometry(0.1, CONE_TOP_RADIUS, WIDTH_PERCENTAGE * 2, 12);
CONE_GEOMETRY.translate(0, 0.5, 0);

/** The geometry of the shaft of the arrow  */
const CYLINDER_GEOMETRY = new CylinderGeometry(CYLINDER_RADIUS, CYLINDER_RADIUS, 1, 3);
CYLINDER_GEOMETRY.translate(0, 0, 0);

/** The geometry of the whole arrow */
const GEOMETRY = BufferGeometryUtils.mergeGeometries([CYLINDER_GEOMETRY, CONE_GEOMETRY]);

/** A gizmo to translate the box */
export class BoxTranslationGizmo extends Gizmo {
	xHandle: TranslationHandle;
	yHandle: TranslationHandle;
	zHandle: TranslationHandle;

	handles: TranslationHandle[] = [];

	/**
	 *
	 * @param element The HTML element on which the scene is rendered
	 * @param camera The camera used in the scene
	 * @param space The space on which the gizmo operates
	 */
	constructor(
		element: HTMLElement,
		camera: Camera,
		public space: TransformSpace,
	) {
		super(element, camera);

		// Create all handles geometries.
		// The matrixWorldAutoUpdate and matrixAutoUpdate are disabled
		// because the composition and decomposition of the matrices does not work
		// with not-uniform scales, so we are managing them manually
		this.xHandle = new TranslationHandle(new Vector3(1, 0, 0), DEFAULT_X_COLORS);
		this.xHandle.matrixWorldAutoUpdate = false;
		this.xHandle.matrixAutoUpdate = false;

		this.yHandle = new TranslationHandle(new Vector3(0, 1, 0), DEFAULT_Y_COLORS);
		this.yHandle.matrixWorldAutoUpdate = false;
		this.yHandle.matrixAutoUpdate = false;

		this.zHandle = new TranslationHandle(new Vector3(0, 0, 1), DEFAULT_Z_COLORS);
		this.zHandle.matrixWorldAutoUpdate = false;
		this.zHandle.matrixAutoUpdate = false;

		this.add(this.xHandle);
		this.add(this.yHandle);
		this.add(this.zHandle);

		this.handles.push(this.xHandle, this.yHandle, this.zHandle);
	}

	/** @inheritdoc */
	override updateMatrixWorld(force?: boolean): void {
		super.updateMatrixWorld(false);

		for (const handle of this.handles) {
			this.computeHandleMatrices(handle, force);
		}
	}

	/**
	 * Show/hide the x axis
	 */
	set showX(show: boolean) {
		this.xHandle.visible = show;
	}

	/**
	 * Show/hide the y axis
	 */
	set showY(show: boolean) {
		this.yHandle.visible = show;
	}

	/**
	 * Show/hide the z axis
	 */
	set showZ(show: boolean) {
		this.zHandle.visible = show;
	}

	/**
	 * Compute the position, rotation and quaternion of the handle based on the current
	 * box configuration and the space the gizmo is working in
	 *
	 * @param handle The handle for which the new 3D configuration should be computed
	 * @param force Flag specyfing if this update was manually forced
	 */
	private computeHandleMatrices = memberWithPrivateData(() => {
		const TEMP_MATRIX_1 = new Matrix4();
		const TEMP_MATRIX_2 = new Matrix4();
		const matrix = new Matrix4();

		const rotationAxis = new Vector3();
		const scale = new Vector3();
		const position = new Vector3();
		const quaternion = new Quaternion();

		const Y_AXIS = new Vector3(0, 1, 0);

		return (handle: TranslationHandle, force?: boolean) => {
			this.getWorldScale(scale);

			// Compute the scaling factor for the AXIS_LENGTH_IN_PIXELS dimension
			let factor = this.computePixelsToMetersFactor(handle, AXIS_LENGTH_IN_PIXELS);
			// Clamp "factor" so that the axis are always shorter than the box and remain inside.
			// The factor should be less than CLAMP_FACTOR * minScale
			const minScale = Math.max(scale.x, Math.max(scale.y, scale.z));
			const CLAMP_FACTOR = 0.4;
			if (factor / minScale > CLAMP_FACTOR) factor = minScale * CLAMP_FACTOR;

			// Compute the rotation axis of the handle: all the handles are cylinder with Y as up direction,
			// so we need to compute the rotation needed to align them with X, Y and Z
			rotationAxis.set(handle.axis.x, handle.axis.y, handle.axis.z).cross(Y_AXIS).normalize();
			if (rotationAxis.length() > Number.EPSILON) {
				matrix.makeRotationAxis(rotationAxis, -Math.PI * 0.5);
			} else {
				// In this case handle.axis is Y
				matrix.identity();
			}

			switch (this.space) {
				case "local": {
					TEMP_MATRIX_1.copy(this.matrixWorld);

					// Take into account the world matrix and the local handle orientation
					// to compute the local scale of the handle
					TEMP_MATRIX_1.multiply(matrix);
					scale.setFromMatrixScale(TEMP_MATRIX_1);

					// Compute the local matrix of the handle, by, in order:
					// 1. scaling the Y-up cylinder based on factor
					// 2. translating the Y-up cylinder along Y
					// 3. rotating the handle based on the which axis it represents (X, Y, Z)
					scale.set(
						(factor * WIDTH_PERCENTAGE) / scale.x,
						factor / scale.y,
						(factor * WIDTH_PERCENTAGE) / scale.z,
					);
					position.set(0, scale.y * 0.5, 0);
					matrix.multiply(TEMP_MATRIX_1.compose(position, quaternion.identity(), scale));
					matrix.decompose(handle.position, handle.quaternion, handle.scale);

					break;
				}
				case "world": {
					// Compute the desired world matrix of the handle, by, in order:
					// 1. scaling the Y-up cylinder based on factor
					// 2. translating the Y-up cylinder along Y
					// 3. rotating the handle based on the which axis it represents (X, Y, Z)
					// 4. translating the handle at the box center
					TEMP_MATRIX_1.makeTranslation(this.getWorldPosition(position));
					TEMP_MATRIX_1.multiply(TEMP_MATRIX_2.makeRotationFromQuaternion(this.quaternion).multiply(matrix));
					TEMP_MATRIX_1.multiply(matrix.makeTranslation(0, factor * 0.5, 0));
					TEMP_MATRIX_1.multiply(
						matrix.makeScale(factor * WIDTH_PERCENTAGE, factor, factor * WIDTH_PERCENTAGE),
					);

					// Compute the local matrix L by inverting the world matrix of the group and multiplying
					// by the desired world matrix
					matrix.copy(TEMP_MATRIX_1);
					matrix.premultiply(TEMP_MATRIX_1.copy(this.matrixWorld).invert());
					matrix.decompose(handle.position, handle.quaternion, handle.scale);
					break;
				}
			}

			// Manually update the matrix of the object and of its children
			handle.matrix.copy(matrix);
			handle.updateMatrixWorld(force);
		};
	});
}

/**
 * An arrow used to translate an object along its direction
 */
export class TranslationHandle extends GizmoHandle {
	/** Name to recognize the Object in the scene graph */
	name = "TranslationHandle";

	/**
	 *
	 * @param axis The direction which the handle points to
	 * @param colors The colors of the handle
	 */
	constructor(
		public axis: Vector3,
		colors: GizmoHandleColors,
	) {
		super(GEOMETRY, new Vector3(), new Euler(), colors);
	}

	/**
	 * Compute the new position of the group after the user dragged the handle
	 *
	 * @param group The group manipulated by the gizmo
	 * @param startPoint The 3D world coordinates of the mouse at the start of the interaction. The function
	 * will replace its value with the current mouse coordinates
	 * @param raycaster The raycaster used during the interaction
	 * @param space Specify if the interaction should be local or global
	 */
	onMouseDrag = memberWithPrivateData(() => {
		const normal = new Vector4();
		const plane = new Plane();
		const axis = new Vector3();
		const endPoint = new Vector3();
		const displacement = new Vector3();
		const matrix = new Matrix4();

		return (group: Group, startPoint: Vector3, raycaster: Raycaster, space: TransformSpace): void => {
			if (!this.parent) return;
			const gizmo = this.parent;

			// Compute the plane used for the interaction: is the plane with the normal equal to
			// the raycaster direction and startPoint as a coplanar point
			plane.setFromNormalAndCoplanarPoint(raycaster.ray.direction, startPoint);

			// Compute the current mouse positio in 3D World
			raycaster.ray.intersectPlane(plane, endPoint);

			// Compute the displacement between the starting and ending point
			displacement.subVectors(endPoint, startPoint);

			if (space === "local") {
				// Compute the local translation axis by applying the world matrix of the group
				normal.set(this.axis.x, this.axis.y, this.axis.z, 0).applyMatrix4(gizmo.matrixWorld).normalize();
				axis.set(normal.x, normal.y, normal.z);
			} else {
				// The translation axis is the world axis associated to the handle
				normal.set(this.axis.x, this.axis.y, this.axis.z, 0).applyMatrix4(matrix.extractRotation(gizmo.matrix));
				axis.set(normal.x, normal.y, normal.z);
			}

			// Project the displacement on the translation axis
			const distance = displacement.dot(axis);

			// Update start point for the next interaction
			startPoint.copy(endPoint);

			// Translate the group position along the axis
			group.position.add(axis.multiplyScalar(distance));
		};
	});
}
