import {
  PropOptional,
  PropRequired,
  validateArrayOf,
  validateNotNullishObject,
  validateOfType,
  validatePrimitive,
} from "@faro-lotv/foundation";

/**
 * Format sent by the backend to describe cad model message.
 *
 * Note: the name and case of the fields is defined from the json contents.
 */
export type CadMessageObjectDescription = {
  /**
   * message type.
   * See https://faro01.atlassian.net/wiki/spaces/BUIL/pages/3639967903/Format+of+json+data+stored+in+the+Model3DStream+node
   * for UI message type description
   */
  Type: string;

  /**
   * message text.
   */
  Text: string;
};

/** Type used for message array. */
export type CadMessagesDescription = CadMessageObjectDescription[];

/**
 * Cad model Levels as it presented in metadata (sent by backend)
 * See https://faro01.atlassian.net/wiki/spaces/BUIL/pages/3639967903/Format+of+json+data+stored+in+the+Model3DStream+node
 * Note: the name and case of the fields is defined from the json contents.
 */
export type CadLevelObjectDescription = {
  /**
   * name of level in CAD (ex “P1 Level”)
   */
  Name: string;

  /**
   * elevation of the level in meter
   */
  Elevation: number;

  /**
   * height of the level in meter
   */
  Height: number;
};

/** Type used for Levels info array. */
export type CadLevelsDescription = CadLevelObjectDescription[];

/** The 3D coordinates of a vector or a point. */
export type Coordinates = {
  X: number;
  Y: number;
  Z: number;
};

/**
 * A 4x4 matrix encoding a 3D transformation.
 *
 */
export type Matrix4x4 = {
  // matrix contents serialized as an array of 16 numbers
  JsonData: number[];
};

/** Description of metadata extracted form the Svf */
export type MetaDataFromSvfDescription = {
  /**
   * Y axis of the model in mesh's coordinates.
   * Together with ModelZInMeshCS, can be used to know the transformation
   * used from model to mesh.
   */
  ModelYInMeshCS: Coordinates;

  /**
   * Z axis of the model in mesh's coordinates.
   * Together with ModelYInMeshCS, can be used to know the transformation
   * used from model to mesh.
   */
  ModelZInMeshCS: Coordinates;

  /**
   * true if the surfaces in the mesh are double sided (back culling should be disabled)
   * false if the surfaces are single sided (back culling is allowed)
   * undefined if not known
   */
  DoubleSidedGeometry?: boolean;

  /**
   * Units used in the model. undefined is the model is unitless.
   */
  DistanceUnits?: string;

  /**
   * Optional up vector of the mesh in model's original coordinate system.
   * Note: model's CS is not the same as the mesh.
   */
  UpVectorInModelCS?: Coordinates;

  /**
   * Optional front vector (toward camera) of the mesh in model's original coordinate system.
   * Note: model's CS is not the same as the mesh.
   */
  FrontVectorInModelCS?: Coordinates;

  /**
   * Optional North vector of the mesh in model's original coordinate system.
   * Some models have no north vector.
   * Note: model's CS is not the same as the mesh.
   */
  NorthVectorInModelCS?: Coordinates;

  /**
   * Optional TrueNorth vector of the mesh in model's original coordinate system.
   * Some models have no north vector.
   * Note: model's CS is not the same as the mesh.
   */
  TrueNorthVectorInModelCS?: Coordinates;

  /**
   * 4x4 transformation matrix to model's optional "reference point".
   */
  RefPointTransform?: Matrix4x4;
};

/**
 * Format sent by the backend to describe metadata for the cad model.
 *
 * Note: the name and case of the fields is defined from the json contents.
 * Only Messages and Levels are defined here for now. Other objects in the json file (Views/Thumbnails)
 * can be added here when there is need.
 */
export type CadMetadataDescription = {
  /**
   * array of model conversion message.
   */
  Messages: CadMessagesDescription;

  /**
   * array of Levels in data.
   */
  Levels: CadLevelsDescription;

  /**
   * Name of the Svf View used for this mesh.
   */
  ExportedSvfView?: string;

  /**
   * List of all Svf Views available in the model.
   */
  AllSvfViews?: string[];

  /**
   * Other metadata extracted from Svf.
   */
  MetaDataFromSvf?: MetaDataFromSvfDescription;
};

/**
 * @returns True if the generic record describes an message object
 * @param data The generic input record
 */
function validateCadMessageObjectDescription(
  data: Record<string, unknown>,
): data is CadMessageObjectDescription {
  return typeof data.Type === "string" && typeof data.Text === "string";
}

/**
 * @returns True if the generic record describes an Level object
 * @param data The generic input record
 */
function validateCadLevelsObjectDescription(
  data: Record<string, unknown>,
): data is CadLevelObjectDescription {
  return (
    typeof data.Name === "string" &&
    typeof data.Elevation === "number" &&
    typeof data.Height === "number"
  );
}

/**
 * @returns True if the object is of type Coordinates
 * @param obj The object being evaluated
 */
export function isCoordinates(obj: unknown): obj is Coordinates {
  return (
    obj !== null &&
    obj !== undefined &&
    validatePrimitive(obj, "X", "number") &&
    validatePrimitive(obj, "Y", "number") &&
    validatePrimitive(obj, "Z", "number")
  );
}

/**
 * @returns True if the object is of type Matrix4x4
 * @param obj The object being evaluated
 */
export function isMatrix4x4(obj: unknown): obj is Matrix4x4 {
  return validateArrayOf({
    object: obj,
    prop: "JsonData",
    elementGuard: (el) => typeof el === "number",
    size: 16,
  });
}

/**
 * @returns True if the generic record describes a MetaDataFromSvfDescription object
 * @param obj The object being evaluated
 */
export function isMetaDataFromSvfDescription(
  obj: unknown,
): obj is MetaDataFromSvfDescription {
  return (
    validatePrimitive(obj, "DoubleSidedGeometry", "boolean", PropOptional) &&
    validatePrimitive(obj, "DistanceUnits", "string", PropOptional) &&
    validateNotNullishObject(obj, "MetaDataFromSvfDescription") &&
    validateOfType(obj, "UpVectorInModelCS", isCoordinates, PropOptional) &&
    validateOfType(obj, "FrontVectorInModelCS", isCoordinates, PropOptional) &&
    validateOfType(obj, "NorthVectorInModelCS", isCoordinates, PropOptional) &&
    validateOfType(obj, "ModelYInMeshCS", isCoordinates, PropRequired) &&
    validateOfType(obj, "ModelZInMeshCS", isCoordinates, PropRequired) &&
    validateOfType(obj, "RefPointTransform", isMatrix4x4, PropOptional)
  );
}

/**
 * @returns True if the generic record describes metadata object
 * @param data The generic input record
 */
export function isCadMetadataDescription(
  data: Record<string, unknown>,
): data is CadMetadataDescription {
  return (
    validateArrayOf({
      object: data,
      prop: "Messages",
      elementGuard: validateCadMessageObjectDescription,
    }) &&
    validateArrayOf({
      object: data,
      prop: "Levels",
      elementGuard: validateCadLevelsObjectDescription,
    }) &&
    validatePrimitive(data, "ExportedSvfView", "string", PropOptional) &&
    validateArrayOf({
      object: data,
      prop: "AllSvfViews",
      elementGuard: (x) => typeof x === "string",
      optionality: PropOptional,
    }) &&
    validateOfType(
      data,
      "MetaDataFromSvf",
      isMetaDataFromSvfDescription,
      PropOptional,
    )
  );
}
